altai-contact-timeline {
  /* -------------------------------- 

Main components 

-------------------------------- */
}
altai-contact-timeline .cd-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
altai-contact-timeline .cd-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}
altai-contact-timeline header {
  height: 200px;
  line-height: 200px;
  text-align: center;
  background: #303e49;
}
altai-contact-timeline header h1 {
  color: white;
  font-size: 18px;
  font-size: 1.125rem;
}
altai-contact-timeline #cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
altai-contact-timeline #cd-timeline::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #d7e4ed;
}
altai-contact-timeline .cd-timeline-block {
  position: relative;
  margin: 2em 0;
}
altai-contact-timeline .cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
altai-contact-timeline .cd-timeline-block:first-child {
  margin-top: 0;
}
altai-contact-timeline .cd-timeline-block:last-child {
  margin-bottom: 0;
}
altai-contact-timeline .cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
altai-contact-timeline .cd-timeline-img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
altai-contact-timeline .cd-timeline-img.cd-picture {
  background: #75ce66;
}
altai-contact-timeline .cd-timeline-img.cd-movie {
  background: #c03b44;
}
altai-contact-timeline .cd-timeline-img.cd-location {
  background: #f0ca45;
}
@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
altai-contact-timeline .cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #d7e4ed;
}
altai-contact-timeline .cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
altai-contact-timeline .cd-timeline-content h2 {
  color: #303e49;
}
altai-contact-timeline .cd-timeline-content p,
altai-contact-timeline .cd-timeline-content .cd-read-more,
altai-contact-timeline .cd-timeline-content .cd-date {
  font-size: 13px;
  font-size: 0.8125rem;
}
altai-contact-timeline .cd-timeline-content .cd-read-more,
altai-contact-timeline .cd-timeline-content .cd-date {
  display: inline-block;
}
altai-contact-timeline .cd-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}
altai-contact-timeline .cd-timeline-content .cd-read-more {
  float: right;
  padding: .8em 1em;
  background: #acb7c0;
  color: white;
  border-radius: 0.25em;
}
altai-contact-timeline .no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}
altai-contact-timeline .cd-timeline-content .cd-date {
  float: left;
  padding: .8em 0;
  opacity: .7;
}
altai-contact-timeline .cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}
@media only screen and (min-width: 768px) {
  altai-contact-timeline .cd-timeline-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  altai-contact-timeline .cd-timeline-content p {
    font-size: 16px;
    font-size: 1rem;
  }
  altai-contact-timeline .cd-timeline-content .cd-read-more,
  altai-contact-timeline .cd-timeline-content .cd-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
